<template>
  <div class="about mt-5">
    <div class="container-fluid">
      <div class="row my-3 justify-content-center">
        <div class="col-10 text-left">
          <h1>
            <span>Kontakt</span>
          </h1>
          <div class="row">
            <div class="col-12 col-lg-6">
              Dirk Werner
              <br />{{ company.name }}
							<br />{{ company.address.street }} {{ company.address.streetnumber }}
							<br />{{ company.address.zip }} {{ company.address.city }}							
              <br />
				<div class="my-3">
					<div class="font-weight-bold">Telefonnummern:</div>
					<div class="d-flex" v-if="company.phone_numbers" >
						<div class="mr-4">
							<div v-for="number in company.phone_numbers" :key="number.id">
								<span v-if="number.role=='primary'">Festnetz</span>
								<span v-if="number.role=='mobile'">Mobil</span>
								<span v-if="number.role=='fax'">Fax</span>
							</div>
						</div>
						<div>
							<div v-for="number in company.phone_numbers" :key="number.id">{{ number.value }}</div>
						</div>
					</div>
					<div v-else>keine Telefonnummern vorhanden.</div>
				</div>
                <div>
                  <div v-if="company.emails && company.emails.length>0" class="font-weight-bold">
                    E-Mails
                  </div>
                  <div>
                      <div v-for="email in company.emails" :key="email.name">
                        <!-- sse -->
                      <a
                        class="text-secondary"
                        :href="'mailto:'+email.value"
                      >{{email.value}}</a>
                      <!-- /sse -->
                      <small class="d-none" v-if="email.name">({{email.name}})</small>
                      </div>
                  </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 text-center">
              <iframe
                height="300"
                width="100%"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2494.0290811793316!2d12.273987815311587!3d51.3105892796033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6f6c24ba300f9%3A0x7bab83a8c6b7b602!2sRatzelstra%C3%9Fe%20188%2C%2004207%20Leipzig!5e0!3m2!1sde!2sde!4v1568383405655!5m2!1sde!2sde"
                style="border:0;"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3 justify-content-center">
        <div class="col-10">
          <div class="row justify-content-center">
            <Branch />
          </div>
          <div class="row justify-content-center">
            <hr class="col-5" />
          </div>
          <div class="text-center text-muted mt-3">Komm vorbei, wir beraten dich gerne.</div>
          <div class="text-center text-muted small mb-3">- Dein Team der {{company.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Branch from "@/components/shared/Branches";
import { mapGetters } from "vuex";

export default {
  name: "contact",
  components: {
    Branch
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      company: "company",
      loaded: "module_company_loaded"
    })
  }
};
</script>

